import { gsap } from "gsap";
import { ScrollToPlugin, Power4 } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

document.addEventListener('DOMContentLoaded', ()=> {
    
    window.runScroll = (target, offset) => {        
        let topbar_height = document.querySelector('.js-topbar').clientHeight;
        gsap.to(window, { 
            duration: 1, 
            scrollTo: { 
                y: document.querySelector(target), 
                offsetY: offset
            }, 
            ease: Power4.easeOut,
        });
    };

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        
        const action = (e) => {
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;
                
            offset === undefined ? offset = 0 : false;
            console.log(target);
            runScroll(target, offset);
            document.documentElement.classList.contains('menu-opened') ? hideMenu() : false;
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        }

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
