import { gsap, Elastic, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {
        if (document.querySelectorAll('.gs-fade').length > 0) {
            gsap.utils.toArray(".gs-fade > *").forEach((section) => {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete reset",
                    },
                    autoAlpha: 0, 
                    duration: 1,
                    y: '50px'
                });
            });
        }
        
        if (document.querySelector('.c-agend .o-block')) {
            gsap.to(document.querySelector('.c-agend .o-block'), {
                scrollTrigger: {
                    trigger: document.querySelector('.c-agend .o-block'),
                    scrub: 2,
                    start: 'top bottom',
                    end: 'bottom top+=200'
                },      
                x: 0
            });
        }
        
        if (document.querySelector('.c-location .o-photo')) {
            gsap.to(document.querySelector('.c-location .o-photo .o-photo__wrapper'), {
                scrollTrigger: {
                    trigger: document.querySelector('.c-location'),
                    scrub: 1,
                    start: 'top bottom',
                    end: 'bottom center-=200'
                },      
                x: -100
            });            
            gsap.to(document.querySelector('.c-location .o-block'), {
                scrollTrigger: {
                    trigger: document.querySelector('.c-location .o-photo .o-photo__wrapper'),
                    scrub: 1,
                    start: 'top center',
                    end: 'bottom center-=200',
                },
                x: 0
            });            
        }
        
/*
        if (document.querySelector('.js-reviews-opinions')) {
            gsap.from('.js-reviews-opinions .swiper-wrapper', {
                scrollTrigger: {
                    trigger: '.js-reviews-opinions',
                    toggleActions: "play complete complete complete",
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },      
                xPercent: 15,
                once: true
            });
        }
*/
        
        if (document.querySelector('.js-special-guests')) {
            gsap.from('.js-special-guests', {
                scrollTrigger: {
                    trigger: '.js-special-guests',
                    toggleActions: "play complete complete complete",
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },      
                xPercent: -10
            });
        }
    };
    
}, false)
