(()=> {
    let scroll_pos = window.pageYOffset || window.scrollY,
        status = false;
    
    let down,
        lastScrollTop = 0;

    const action = () => {   
        let st = window.pageYOffset || document.documentElement.scrollTop;
       
        if (st > lastScrollTop){
           down = true;
        } else {
            down = false;
        }

        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
            
        if (down === true) {
            if (status === false) {
                document.documentElement.classList.add('scroll-down');
                status = true;
            }
        } else {
            if (status === true) {
                document.documentElement.classList.remove('scroll-down');
                status = false;
            }
        }
    }

    window.addEventListener('scroll', action);    
})();