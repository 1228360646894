import css from './sass/style.scss';

require('./js/anims')
require('./js/carousels')
require('./js/fonts')
require('./js/init')
require('./js/nav')
require('./js/polyfills')
require('./js/scrollpos')
require('./js/smoothscroll')
require('./js/svg2inline')
require('./js/text-roller')
