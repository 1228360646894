import Swiper from 'swiper';
import { Autoplay, EffectFade, FreeMode, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/modules/autoplay.scss';
import 'swiper/modules/effect-fade.scss';
import 'swiper/modules/free-mode.scss';
import 'swiper/swiper.scss';

document.addEventListener('DOMContentLoaded', () => {
    
    const reviews_gallery = document.querySelector('.js-reviews-gallery'),
          reviews_opinions = document.querySelector('.js-reviews-opinions'),
          special_guests = document.querySelector('.js-special-guests');

    const reviews_gallery_carousel = () => {
        const swiper_reviews_gallery = new Swiper(reviews_gallery, {
            modules: [Autoplay, EffectFade, Pagination],
            allowTouchMove: false,
            autoplay: {
                delay: 4000,
            },
            effect: "fade",
            loop: true,
            pagination: {
                el: reviews_gallery.parentNode.querySelector(".swiper-pagination"),
                type: "progressbar",
            },
            speed: 600
        });
    }
    
    const reviews_opinions_carousel = () => {
        const swiper = new Swiper(reviews_opinions, {
            modules: [FreeMode, Scrollbar],
            centeredSlides: false,
            freeMode: true,
            grabCursor: true,
            scrollbar: {
                el: reviews_opinions.querySelector('.swiper-scrollbar'),
                draggable: true,
                hide: false
            },
            slidesPerView: 'auto',
            speed: 800
        });
    };
    
    const special_guests_carousel = () => {
        let mediaQuery = '(max-width: 1024px)',
            mediaQueryList = window.matchMedia(mediaQuery),
            swiper;

        const start = ()=> {
            swiper = new Swiper(special_guests, {
                modules: [FreeMode, Pagination, Scrollbar],
                freeMode: true,
                grabCursor: true,
                scrollbar: {
                    el: special_guests.querySelector('.swiper-scrollbar'),
                    draggable: true,
                    hide: false
                },
                slidesPerView: 'auto',
                speed: 800
    	    });
        }

        mediaQueryList.addEventListener('change', event => {
            event.matches ? start() : swiper.destroy();
        })

        mediaQueryList.matches ? start() : false;
    };
   
    window.carousels = () => {
        reviews_gallery ? reviews_gallery_carousel() : false;
        reviews_opinions ? reviews_opinions_carousel() : false;
        special_guests ? special_guests_carousel() : false;
    }

}, false)
